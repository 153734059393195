body {
  /*background-color: #f3fff6;*/
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}


@keyframes fade-in {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

.fade-in {
  animation: fade-in 0.5s ease-in;
}


.gm-style-iw + div {display: none;}

.gm-ui-hover-effect {
  display: none !important;
}

.gm-style-iw div {

}




.side-panel {
  position: fixed;
  top: 45px;
  right: 0;
  height: 90vh;
  background-color: #eee;
  border-left: 1px solid #ccc;
  padding: 20px;
  z-index: 9;
  overflow-y: auto; /* Agrega esta propiedad para permitir el desplazamiento vertical */
  -webkit-overflow-scrolling: touch; /* Habilita el desplazamiento suave en dispositivos móviles */
}

/* Personaliza la apariencia de la barra de desplazamiento (opcional) */
.side-panel::-webkit-scrollbar {
  width: 8px;
}

.side-panel::-webkit-scrollbar-thumb {
  background-color: #888;
  border-radius: 4px;
}

.side-panel::-webkit-scrollbar-thumb:hover {
  background-color: #555;
}




.notific-list {
  list-style: none;
  max-width: 400px;
  margin: 20px auto;
  background-color: #fff;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
}

.notific {
  display: flex;
  padding: 10px;
  border-bottom: 1px solid #eee;
}

.avatar {
  width: 40px;
  height: 40px;
  border-radius: 50%;
  margin-right: 10px;
}

.notific-content {
  flex: 1;
}

.notific-text {
  display: block;
  color: royalblue;
  font-size: 12px;
  font-weight: bold;
  margin-bottom: 5px;
}

.notific-time {
  color: #888;
}

/* Optional: Hover effect */
.notific:hover {
  background-color: #f7f7f7;
  cursor: pointer;
}


.no-dots {
  list-style: none;
}


.react-confirm-alert-overlay {
  z-index: 9999 !important;
}


/* Estilos para el elemento li activo */
.navbar-nav .nav-item.active {
  background-color: #007bff; /* Cambia este color según tus preferencias */
  /* Otros estilos que desees aplicar */
  font-weight:bold;
}

/* Estilos para el enlace dentro del elemento li activo */
.navbar-nav .nav-item.active .nav-link {
  color: #fff; /* Cambia este color según tus preferencias */
  /* Otros estilos que desees aplicar */
  font-weight:bold;
}

.divRounded {
  width: 42px;
  height: 42px;
  border-radius: 50%;
  background-color: royalblue;
  justify-content: center;
  align-items: center;
  color: white;
  font-size: 24px;
  text-align: center;
}

.divRoundedSmall {
  width: 30px;
  height: 30px;
  display:inline-block;
  border-radius: 50%;
  background-color: royalblue;
  justify-content: center;
  align-items: center;
  color: white;
  font-size: 20px;
  text-align: center;
}


.divRounded2 {
  width: 60px;
  height: 60px;
  border-radius: 50%;
  background-color: cornflowerblue;
  justify-content: center;
  align-items: center;
  color: white;
  font-size: 35px;
  text-align: center;
}


.divRounded3 {
  width: 120px;
  height: 120px;
  border-radius: 50%;
  background-color: lightgray;
  justify-content: center;
  align-items: center;
  color: white;
  font-size: 70px;
  text-align: center;
}

.footer{
  border-top: 2px solid #efefef;
  height: 55px;
  background-color: #fff;
}

.f-nav-m-cont{
  /*background-color:red;*/
  margin:0 auto;
  color:#666;
  height:35px;
  line-height:0;
  font-weight:0;
  font-size:18px;
  text-decoration: none !important;
}

.f-nav-m-cont.active {
  color: #1a5cdf;
  font-weight:bold;
}

.f-nav-m-cont.active span{
  font-size:20px;
}

.f-nav-m-cont div{
  font-size:12px;
  margin-top:8px;
  padding:0
}

.hdr-item{
  width:100px;
  text-align:center;
}

.bg-blurred {
  background-color: rgba(255, 255, 255, 0.8); /* Cambia la opacidad según tus preferencias */
  backdrop-filter: blur(10px); /* Ajusta el nivel de desenfoque según tus preferencias */
  transition: background-color 0.3s ease, backdrop-filter 0.3s ease;
}

.navbar-nav .nav-item.itm-bordr{
  border-right: 1px solid #2ee0ff;
}


.clear-list{
  width:100%;
  padding:10px;
  /*background-color:blue;*/
  list-style-type:none;
}




label.error, #card-errors{
  font-size:12px;
  color:orangered;
  margin-top:7px;
}



.subs-hdr{
  background-color: #17a2b8;
  padding: 20px;
  color: white;
  border-radius: 50px;
}


.home-badges{
  border-radius:20px;
  padding:15px;
  line-height:20px;
}

.home-badges p{
  margin-top:5px;
  color:#666;
}



.home-badges.purple{
  background-color: #f6f0ff;
  color: #333;
}


.home-badges.white{
  border-radius:20px;
  border:1px solid #ddd;
  color: #333;
  background-color: #fdfffb;
}

.home-badges.yellow{
  background-color: #fffae7;
  color: #333;
}

.home-badges.green{
  background-color: #f1ffe6;
  color: #333;
}

.home-badges.blue{
  background-color: #e5f9ff;
  color: #333;
}

.sect-logo{
  width:250px;
  margin-bottom:20px;
}


.place-srch{
  width: 100px;
  margin: 0;
}

.btn.btn-outline-success-2{
  border-color: #a0ddae;
}


.btn.btn-outline-success-2:hover{
  background-color: #f3fff6;
}


.no-spinners {
  /* Ocultar las flechas en Chrome y Safari */
  -webkit-appearance: none;
  appearance: none;

  /* Estilo adicional para Firefox */
  /* En Firefox, puedes usar una regla similar para ocultar las flechas */
  /* Pero necesitas agregar "-moz-appearance: textfield" para eliminar algunos estilos adicionales de Firefox */
  -moz-appearance: textfield;
}

/* Estilos adicionales para Firefox */
.no-spinners::-webkit-inner-spin-button,
.no-spinners::-webkit-outer-spin-button,
.no-spinners::-webkit-clear-button {
  display: none;
}


.modal-backdrop {
  z-index: 1055
}
.modal-backdrop.show {
  opacity: 0.7;
}